import { debug } from './debug-log';

/**
 * Displays the Manufacturer Announcement (MAN) Layer on the page.
 * This function is called from the external file called man_main.js file (Not maintained by Ads team)
 * The ad content is provided by the external team, and we are responsible for wrapping it with a title, header, and close button.
 * @param {HTMLElement} adNode - The ad content to be displayed in the MAN Layer.
 */
export function manLayer(adNode) {
    const that = this;
    if (!adNode) return;

    // DO NOT load MAN Layer on landscape orientation in mobile
    if (that.DetectUA({}).isMobile && that.utils.getScreenOrientation() === 'landscape') return;

    const originContainer = document.getElementById('man-layer-id');
    const bodyClass = 'man-layer-visible';
    const closeClass = 'man-close';
    const body = document.body;

    // Add class to body to indicate MAN Layer is visible
    body.classList.add(bodyClass);
    const manContent = adNode;
    manContent.classList.add('man-content');

    // Create whiteout layer to cover the background
    const whiteOutLayer = that.utils.createElement({
        name: 'div',
        className: 'man-whiteout-layer'
    });

    // Create MAN Layer header with title and close button
    const manHeader = that.utils.createElement({
        name: 'div',
        className: 'man-header',
        children: [
            {
                name: 'span',
                className: 'man-ifi',
                text: 'Information from Industry'
            },
            {
                name: 'span',
                className: 'man-header-title',
                text: 'Manufacturer Announcement'
            },
            {
                name: 'span',
                className: closeClass,
                text: 'close'
            }
        ]
    });

    // Create MAN Layer container
    const manLayer = that.utils.createElement({
        name: 'div',
        className: 'man-ad-layer',
        id: 'man-layer'
    });

    // Append header and content to MAN Layer
    manLayer.appendChild(manHeader);
    manLayer.appendChild(manContent);

    // Append whiteout layer and MAN Layer to body
    body.appendChild(whiteOutLayer);
    body.appendChild(manLayer);

    // Add event listeners to close the MAN Layer
    document.querySelector(`.${closeClass}`).addEventListener('click', closeMAN);
    const ctaClose = document.querySelector('.MAN_CTA_CLOSE');
    if (ctaClose) {
        ctaClose.addEventListener('click', closeMAN);
    }

    /**
     * Closes the MAN Layer and performs cleanup.
     * @param {Event} e - The event object.
     */
    function closeMAN(e) {
        // Do not close if on different page than the creative
        if (e.target.classList.contains('MAN_CTA_CLOSE')) {
            const url = e.target.dataset.url ? e.target.dataset.url : e.target.href;
            const articleID = window.PageMetadata?.pageSegVars?.art.replace('drg', '');
            if (url.indexOf(articleID) === -1) {
                window.open(url);
                return;
            }
        }
        whiteOutLayer.style.display = 'none';
        manLayer.remove();
        if (originContainer) {
            originContainer.remove();
        }
        body.classList.remove(bodyClass);
        if (window.wmdPageLink && typeof window.wmdPageLink === 'function') {
            window.wmdPageLink('man_dsms', e.target);
        }
    }

    // Attempt to update PIMC (Program Interruption Management Counter)
    try {
        profads.ads2.updatePIMC();
    } catch (e) {
        debug.warn("[profads] Error occurred in updating PIMC");
    }
}