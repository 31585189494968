function setPPID() {
  const ppidValue = generatePPID();
  const domain = window.location.host.split(".").slice(-2).join(".");
  document.cookie = `ppid=${ppidValue};expires=${setPPIDExpiry()};domain=.${domain};path=/;secure`;
  try {
    profads.ads2.setPIMC(0, true);
  } catch (e) {
    debug.log("[profads] Error occured while setting PIMC in cookie");
  }
  return ppidValue;
}

function getPPID() {
  const ppidExists = document.cookie.match(/ppid=[a-zA-Z0-9]+/);
  const ppidValue = ppidExists
    ? ppidExists[0].split("=")[1]
    : setPPID();
  if (ppidValue) {
    webmd.ads2.setPublisherProvidedId(ppidValue);
  }
}

function generatePPID(length = 42) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  let timestamp = Math.round(Date.now() / 1000);
  return result + timestamp;
}

function setPPIDExpiry() {
  let now = new Date(); // current date
  let currentTime = now.getTime(); //in millisecs
  let expireTime = currentTime + 180 * 24 * 60 * 60 * 1000; // expire after 180 days
  now.setTime(expireTime);
  return now.toUTCString();
}


export default getPPID;