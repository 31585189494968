import {debug} from './debug-log';
/* Start Shared consumer framework */
// webmd.ads2.js
// DEPENDENCIES: jQuery
// This library is used on WebMD, Boots, O&O, and Medscape
window.googletag = window.googletag || {};

// Create webmd object if it doesn't already exist
window.webmd = window.webmd || {};

// initialize the webmd.tpsvc namespace
webmd.tpsvc = webmd.tpsvc || {};

// initialize the webmd.tps command queue - a queue for storing third party service commands
webmd.tpsvc.cmds = webmd.tpsvc.cmds || [];

// if the 3rd-party service implements a promise, initialize the webmd.tpsvc promise queue
// the promise is a placeholder into which response from the 3rd-party service is inserted.
webmd.tpsvc.promises = webmd.tpsvc.promises || [];

// initialize mobOptCheck, create null object if it doesn't already exist
window.mobOptCheck = window.mobOptCheck || null;


// check for admob, if not present -> create a object with a function that returns null
window.admob = window.admob || {};
admob.events = admob.events || {};
admob.events.dispatchAppEvent = admob.events.dispatchAppEvent || function() { return null };


// NOTE: developers should not directly call google code, they should only use functions in webmd.ads namespace.  If
// additional functionality is needed, it should be added to webmd.ads namespace so we can have a single interface into
// the google code.

// Set up array of functions to run after the Google code loads.
// google.cmd is the array of functions to run.
// You must use google.cmd.push() to add functions (don't do this directly, use webmd.ads.googleDefer instead).
// After the Google code loads, it replaces the push() function so instead of adding to the array,
// it runs the function immediately.
googletag = googletag || {};
googletag.cmd = googletag.cmd || [];

/**
 * Ads framework
 */
webmd.ads2 = {

    /**
     * Storage for ads that have been defined.
     * @private
     */
    ads: {},

    /**
     * Ad target values for all ads on the page.
     * This is an array of strings that will be concatenated to form the ad target.
     * For example, if you want the ad slot /1234/travel/asia/food, then the array
     * would be ['travel','asia','food'].
     *
     * <p>This parameter can be overridden, or more values can be added, but you
     * must do so before calling defineAd()</p>
     *
     * @example
     * // Override the ad target
     * webmd.ads2.adTarget = ['consumer', 'rxlist'];
     *
     * @example
     * // Override the second part of the target
     * webmd.ads2.adTarget[1] = 'rxlist';
     *
     * @example
     * // Set sensitive topic exclusion for consumer webmd
     * webmd.ads2.adTarget[2] = 'st-conwbmd';
     */
    adTarget: ['consumer', 'webmd'],

    /**
     * Google network code that is sent with each ad.
     * This, along with the adTarget parameter, is used when defining the ad slots.
     */
    networkCode: function () {
        var networkId = '4312434'; //default live network id
        const urlParams = new URLSearchParams(window.location.search);
        debug.log("[webmd] [networkCode]",networkId);
        return networkId;
    },

    /**
     * Storage for the page targets that have been set for all ads.
     * We don't technically need to save these, but this can be used
     * for reference after ads have been set up.
     * In particular it is used to determine when certain ads should be
     * blocked due to "safe select" requirements.
     *
     * @example
     * if (webmd.ads2.pageTargets.pt == '1623') { ... }
     */
    pageTargets: {},

    /**
     * Storage for the page exclusions that have been set for all ads.
     * We don't technically need to save these, but this can be used
     * for reference after ads have been set up.
     * In particular it is used to determine when certain ads should be
     * blocked due to "safe select" requirements.
     *
     * @example
     * if (webmd.ads2.pageExclusions.age11)) { ... }
     */
    pageExclusions: {},

    /**
     * Size overrides for individual ad positions.
     * This is an object with key/value pairs where the key is a pos value,
     * and the value is a size array like those passed into webmd.ads2.defineAd()
     *
     * <p>
     * This can be used to set default sizes for all the ad positions
     * so you do not have to pass the size when you define the ad.
     * </p>
     *
     * <p>
     * It can also be used to define size overrides, in case you define an
     * ad with a set of sizes, but on certain pages you want to override those sizes.
     * In this case you must set the size override before defining the ad.
     * </p>
     *
     * <p>
     * Note: if a size is set here, any size passed to defineAd() is ignored.
     * </p>
     *
     * @example
     * // Set sizes for various ad positions
     * webmd.ads2.sizes = {
     *   101: [ [300, 250], [300,600] ],
     *   121: [ 300, 250 ]
     * };
     * // ...
     * webmd.ads2.defineAd({pos:101, id:'ads2-pos-101'});
     *
     * @example
     * // Override the size for a single ad on this page
     * webmd.ads2.sizes['101'] = [300,250];
     */
    sizes: {

        // posValue: sizesArray,
        // ...

    },

    /*==================================================
     * START OF FUNCTIONS
     *==================================================*/

    /**
     * Function to call once to initialize the ads framework.
     * Can be done at the top or bottom of the page.
     * Calling this multiple times will have no ill effects.
     */
    init: function () {

        // Do a check to make sure nobody tries to run init twice,
        // because that might really screw things up.
        if (this.init.flag) {
            return;
        }
        debug.log("[webmd] Inside Init function");
        this.init.flag = true;

        // Load the javascript library from google
        this.googleLoad();
        this.sandboxInit();
        // Allow this function to be chained
        return this;
    },

    /**
     * Function to tell if init() has already been called.
     * You can use this to determine if DFP ads are defined on the page.
     * NOTE: this must be called after webmd.ads2.init() has been called,
     * otherwise it will report that DFP ads are not on the page.
     * @returns Boolean
     */
    isInitialized: function () {
        debug.log("[webmd] [isInitialized] ",this.init.flag);
        return Boolean(this.init.flag);
    },

    /**
     * Add a function to the google asynchronous command queue.
     *
     * @param Function f Function to run after google code has loaded.
     * @param Number i Index for the new function to be pushed into.
     */
    googleDefer: function (f) {
        // Push the function onto the command array so google can run it later.
        //
        // NOTE: After the google code loads, it actually overrides the push() function
        // so instead of adding to the array, it runs the function immediately.
        // Because of this you should only modify the array using the push() function,
        // you should not attempt to add functions to the queue any other way.
        debug.log("[webmd] [googleDefer] function pushed into the googletag.cmd array");
        googletag.cmd.push(f);

        // Allow this function to be chained
        return this;
    },

    /**
     * Call this once to load the google code.
     * For best results call this near the top of the page.
     * This function is called automatically by init()
     */
    googleLoad: function () {
        if(profads.ads2.GDPR_optin_check()) {
            var gads, node, useSSL;

            // Asynchronously load the google code
            gads = document.createElement("script");
            gads.async = true;
            gads.type = "text/javascript";
            useSSL = "https:" === document.location.protocol;
            gads.src = (useSSL ? "https:" : "http:") + "//securepubads.g.doubleclick.net/tag/js/gpt.js";
            node = document.getElementsByTagName("script")[0];
            node.parentNode.insertBefore(gads, node);
            debug.log("[webmd][googleLoad] JS file path",gads.src);
            // Allow this function to be chained
            return this;
        }
    },

    /**
     * Set a page-level key/value target that will be used for all ads.
     * This function can set a single key/value, or multiple key/value pairs.
     * The value can be a single value, or an array of values.
     *
     * @param String|Object key The name of the key to add, or an object containing multiple key/value pairs.
     *
     * @param String|Array [value] If the "key" parameter is a string, then this is the value for the single key to set.
     * If the "key" parameter is an object, this parameter is not used.
     * The value can be a string, or an array of values.
     * The value cannot be a number, however it can be an array of numbers (possible bug in google code?)
     *
     * @example
     * // Set a single key/value
     * webmd.ads2.setPageTarget('xpg', '1234');
     *
     * @example
     * // Set a single key with multiple values
     * webmd.ads2.setPageTarget('xpg', ['1234', '5678']);
     *
     * @example
     * // Set multiple key/value pairs all at once
     * webmd.ads2.setPageTarget({xpg:'1234', gender:'male', m:[1,2,5]});
     */
    setPageTarget: function (key, value) {
        debug.log("[webmd][setPageTarget] key value",key,value);
        let self = this;

        // We can't call the google code until it is loaded,
        // so we'll add a command to the queue to run later
        self.googleDefer(function () {

            // Check if "key" is an object
            if(typeof key === 'object' && key !== 'undefined' && typeof key !== 'text') {
                // Loop through multiple key/value pairs
                for(let i in key) {
                    value = String(self.fixTarget(i, key[i]));
                    // Add the key and value
                    // Note: for some reason the DFP code does not allow
                    // numeric values, so cast the value to a String
                    googletag.pubads().setTargeting(i, key[i]);

                    // Save the key and value for later reference
                    self.pageTargets[i] = key[i];
                }

            } else { // Add a single key and value
                value = String(self.fixTarget(key, value));
                googletag.pubads().setTargeting(key, value);
                self.pageTargets[key] = value;
            }
        });

        // Allow this function to be chained
        return this;
    },
    fixTarget: function (key, value) {

        // Fix some of the targets because runtime sends some extraneous text that we don't want
        if (key === 'env') {
            value = value.replace(/&amp;env=/, '');
        }
        if (key === 'leaf') {
            value = value.replace(/&amp;leaf=/, '');
        }
        if (key === 'uri') {
            value = decodeURIComponent(value);
        }
        debug.log("[webmd][fixTarget]",key,value);
        return value;
    },

    /**
     * Set multiple page-level key/value targets that will be used for all ads,
     * based on a URL-encoded string that contains multiple key=value pairs.
     *
     * <p>This function is provided to more easily support legacy systems
     * that are already outputing a URL-encoded string. It should not be used
     * for new code.</p>
     *
     * @param {String} url A URL-encoded value (sort of). This must contain
     * key=value pairs, separated by '&amp;'. Note it will not work if the
     * key=value pairs are separated by '&'.
     *
     * @example
     * webmd.ads2.setPageTargetUrl('key1=val1&amp;key2=val2');
     */
    setPageTargetUrl: function (url) {

        var i, key, keyValueArray, keyValuePairs, value;

        debug.log("[webmd][setPageTargetUrl]",url);
        // Make sure url is actually passed in as a string
        url = url || '';

        // Split the string on '&amp;'
        keyValuePairs = url.split('&amp;');

        // Loop through the key=value pairs
        for (i = 0; i < keyValuePairs.length; i++) {

            // Split into [key,value]
            keyValueArray = keyValuePairs[i].split('=');
            key = decodeURIComponent(keyValueArray[0] || '');
            value = decodeURIComponent(keyValueArray[1] || '');

            // If we got a key, set the page target
            if (key) {
                this.setPageTarget(key, value);
            }
        }

        // Allow this function to be chained
        return this;
    },

    /**
     * Sets a "category exclusion" for all ads on the page.
     *
     * <p>Note we currently do not support setting a category exclusion just for a single ad.</p>
     *
     * @param String|Array label The name of the category exclusion label, or an array of names.
     *
     * @example
     * webmd.ads2.setPageExclusion('gen2');
     *
     * @example
     * webmd.ads2.setPageExclusion('gen2', 'age11');
     */
    setPageExclusion: function (label) {
        let self = this;

        // Turn label into an array if it is not already an array
        label = Array.isArray(label) === true ? label: new Array(t);

        // Wait for google code to be loaded
        self.googleDefer(function () {

            // Loop through the array of labels and set a category exclusion for each one
            label.forEach((l) => {
                googletag.pubads().setCategoryExclusion(l);
                // Save the exclusion label for later reference
                self.pageExclusions[l] = true;
            });
        });

        debug.log("[webmd][setPageExclusion] label",label);
        // Allow this function to be chained
        return self;
    },

    /**
     * Sets multiple "category exclusions" based on a concatenated string.
     * This is provided for legacy systems that used to pass a value like
     * "_label1_label2_" to the ad server. This function will break apart
     * the string into multiple exclusion labels.
     *
     * @param String labels A string containing one or more labels separated
     * by an underscore character.
     *
     * @example
     * webmd.ads2.setPageExclusionUnderscore('_gen2_m32_h16_o92_age11_age121_age122_');
     */
    setPageExclusionUnderscore: function (labels) {
        let self = this;

        // Make sure we actually got a string
        labels = labels || '';

        Array.from(labels.split('_')).forEach((l) => {
            if(l) self.setPageExclusion(l);
        });
        debug.log("[webmd][setPageExclusionUnderscore] labels",labels);
        // Allow this function to be chained
        return this;
    },

    /**
     * Define an ad slot on the page. Generally you will have already created a div on the page as the ad placeholder,
     * then call this function and pass in the id of that div.    However, you can call this function if necessary before
     * the div has been created.
     *
     * @param Object settings A set of key/value pairs that configures the ad.
     *
     * @param Boolean [settings.collapseAfter] Set this to true if you want the ad to collapse when
     * the ad server returns a blank. If this is not specified then the ad will use
     * the page default behavior (which is to not collapse the ad).
     *
     * @param Boolean [settings.collapseBefore] Set this to true if you want the ad to be
     * collapsed immediately. The ad will expand if the ad server returns an ad.
     * If this is not specified then the ad will use the page default behavior
     * (which is to not collapse the ad).
     *
     * @param String settings.id The id attribute for the ad placeholder div.
     * Each ad must have a unique id. For example, 'ads2-pos-5000'.
     *
     * @param Boolean [settings.immediate=false] Immediately display the ad you defined.
     * This only has an effect if you are defining a new ad after the initial ad call has already been made.
     * It refreshes only the ad that is being defined. If you need to refresh other ads on the
     * page you should not use this.
     *
     * @param Object [settings.keys] Optional set of key/value pairs to set targeting only for
     * this individual ad. Note we typically do not set targeting for individual ads,
     * so use setPageTarget() if you want to set a global target used by all ads.
     *
     * @param String settings.pos The POS value for the ad. Each ad on the page must have a POS
     * value that is unique on the page. For example, '5000'.
     *
     * @param Object [settings.refresh=true] Set this to false if this ad should
     * not be refreshed when calling the webmd.ads2.refresh() function.
     *
     * @param Array [settings.sizes=webmd.ads2.sizes] An array of sizes for the ad.
     * This can be width height values, or multiple width/height pairs.
     * For example, [350,250] or [[300,250],[300,600]].
     * If a value is set within webmd.ads2.sizes for the POS value of this ad, then this parameter
     * is ignored, and the value within webmd.ads2.sizes is used instead.
     * A size must be defined using one of these methods or an error will occur.
     *
     * @example
     * // Ad that supports a single size
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[300,250]});
     *
     * @example
     * // Ad that supports two different sizes
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[[300,250], [300,600]]});
     *
     * @example
     * // Create an ad that should not be refreshed
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[300,250], refresh:false});
     *
     * @example
     * // Set default ad size so the size does not have to be supplied in the defineAd function
     * webmd.ads2.sizes['5000'] = [300,600];
     * // ...
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000});
     *
     * @example
     * // Override an ad size
     * webmd.ads2.sizes['5000'] = [300,600];
     * // ...
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[300,250]});
     * // Result: ad will use sizes [300,600] instead of [300,250]
     *
     * @example
     * // Define a new ad after the other ads have already loaded,
     * // and immediately load that ad plus refresh the other ads
     * webmd.ads2.defineAd({id:'my-new-ad', pos:121, sizes:[300,250]}).refresh()
     *
     * @example
     * // Define a new ad after the other ads have already loaded,
     * // and immediately load only that ad
     * webmd.ads2.defineAd({id:'my-new-ad', pos:121, sizes:[300,250]}).refresh({id:'my-new-ad'})
     */
   
    defineAd: function (settings) {
        var ignore, ignoreIds, self, sizes;
        debug.log("[webmd][defineAd] before settings", settings);
        self = this;

        // Make sure settings is an object
        settings = settings || {};
        if(!webmd.ads2.ads.hasOwnProperty(settings.id)){
        $("#"+settings.id).addClass("globalpromoadsunit");

        // Check if this ad should be ignored based on a global override.
        // Uses a global variable "ads2_ignore", which must be an object
        // where the key is the pos value of the ad that should be ignored,
        // and the value is a boolean (true=ignore the ad).
        // This is used by certain products such as Answers that
        // need to deploy a single template with multiple ads,
        // then show or hide various ads based on back-end logic.
        //
        // Examples:
        //
        // // Ignore ad position 101
        // var ads2_ignore = { 101: true };
        //
        // // Ignore all the ads
        // var ads2_ignore = { all:true };
        /*
        *  // Ignore ad by id
        *  ads2_ignoreIds['ads-pos-122'] = true;
        * */

        ignore = window.ads2_ignore || {};
        ignoreIds = window.ads2_ignoreIds || {};

        if (ignore.all || ignore[settings.pos] || ignoreIds[settings.id]) {
            return;
        }
        
        if(mobOptCheck == "true"){
            window.textDriverOptimized = mobOptCheck;
        }
        if(document.getElementById('ads-af-pos-145') !== null && breakpoint ===1){
            settings.id = 'ads-af-pos-1145';
            document.getElementById('ads-af-pos-145').remove();
        }
        // Check if sizes were provided.
        // If webmd.ads2.sizes contains sizes for this POS value, use that to override the size.
        // Otherwise use the sizes that are passed into this function settings.sizes
        settings.sizes = self.sizes[settings.pos] || settings.sizes;

        // Check for required settings
        if (!(settings.id && settings.sizes && settings.pos)) {
            throw ('Missing id: ' + settings.id + ', pos:' + settings.pos + ' or sizes for defineAd');
        }

        // Save these settings because we will use them later to actually display the ads
        self.ads[settings.id] = settings;

        //add default size if not present
        settings.sizes = self.addFluidSize(settings);
        debug.log("[webmd][defineAd] after settings",settings);
        // Add to the google command queue
        self.googleDefer(function () {


            //webmd.debug('define ad');

            var adUnit, adSlot;

            adUnit = '/' + self.networkCode() + '/' + self.adTarget.join('/');

           //sandbox check
           if (typeof self.sandboxPos !== 'undefined') {
            const posIndex = self.sandboxPos.indexOf(settings.pos.toString());
                if(posIndex !== -1 ){
                    const sandboxEcd = self.sandboxSecd[posIndex];
                    adUnit = '/' + '8668145' + '/' + self.adTarget.join('/');
                    settings.keys = settings.keys || {};
                    settings.keys['ecd'] = sandboxEcd;
                    debug.log("[webmd][defineAd] Inside sandbox",adUnit,posIndex,sandboxEcd);
                }
            }
           //sandbox check end
            // Enable with lazyloading with defaults.
            if(settings.lazyload) googletag.pubads().enableLazyLoad();

            // Create the ad slot and set the "pos" key
            
                adSlot = googletag.defineSlot(adUnit, settings.sizes, settings.id)

                // Ads can have different services associated with them, so add a service
                .addService(googletag.pubads())

                // Set the pos key for this individual ad
                .setTargeting('pos', settings.pos);
            
            // Save the ad slot for future use when we refresh ads
            settings.slot = adSlot;

            // Set extra individual targeting keys if any were provided
            if (settings.keys) {
                $.each(settings.keys, function (key, value) {
                    adSlot.setTargeting(key, value);
                });
            }
            //set ad_slot key in slot level to get the id 
            adSlot.setTargeting('ad_slot', settings.id);
            // set the slot level value for this position
            if (typeof strnativekey !== "undefined" && strnativekey[settings.id]) {
                adSlot.setTargeting("strnativekey", strnativekey[settings.id]);
            }
            if((settings.id == "ads-pos-1420" || settings.id == "ads-pos-1520") && settings.addDis){
                adSlot.setTargeting("dis",settings.addDis);
            }
             
            // Override the page-default collapse behavior
            // (only if one of these is set to true)
            if (settings.collapseAfter || settings.collapseBefore) {
                adSlot.setCollapseEmptyDiv(settings.collapseAfter, settings.collapseBefore);
            }

            // Special case: if we define an ad after webmd.ads2.display() has been called,
            // then we need to tell DFP to display the ad immediately.
            // Note this does not make a call to fetch the ad from DFP unless you set
            // the "immediate" flag, it only initializes the ad space.
            // Otherwise you must call refresh() to fetch the ad
            if (self.display.flag && settings.display !== false) {

                const fn = function () {
                    debug.log(`[webmd][defineAd] Display flag : ${self.display.flag} and ID : ${settings.id}`);
                    googletag.display(settings.id);
                };

                fn();

                // Determine if we should immediately fetch this new ad.
                // If we do not immediately fetch the ad, you must later
                // call refresh() to refresh this ad (or refresh all ads).
                if (settings.immediate) {
                    debug.log(`[webmd][defineAd] immediate refresh flag : ${settings.immediate} and ID : ${settings.id}`);
                    self.refresh({
                        id: settings.id
                    });
                }
            }
        });
        // Allow this function to be chained
        return this;
     } else {
        debug.warn(`[webmd][defineAd] Slot for ${settings.id} was already allocated!!`);
     }
    },

    slotRenderEnded: function () {
        this.googleDefer(function () {
            googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                window.dispatchEvent(new CustomEvent('slotRenderEndedAds', { detail: event }));
                if (typeof webmd.ads2.slots !== 'undefined') {
                    totCalledSlots = webmd.ads2.slots.length;
                } else {
                    var totCalledSlots = 0;
                    for (var slotK in webmd.ads2.ads) {
                        if (webmd.ads2.ads.hasOwnProperty(slotK)) {
                            ++totCalledSlots;
                        }
                    }
                }
                slotCount++;
                if (totCalledSlots == slotCount) {
                    slotCount = 0;
                    debug.log("[webmd][slotRenderEnded] called");
                    $(document).trigger("dfpRenderComp");
                }
            });
        });

        // Allow this function to be chained
        return this;

    },

    addSlotRenderEndedCallback: function (slotid, callback) {
        function checkAdsToWatch(slotId) {
            var adsToWatch = ['101', '122', '141', '910', '1122'];
            var shouldWeWatch = '';
            var i;
            
            for (i = 0; i < adsToWatch.length; i++) {
                if (slotId.indexOf(adsToWatch[i]) > -1) {
                    shouldWeWatch = adsToWatch[i];
                }
            }
            
            return shouldWeWatch;
        }
        
        this.googleDefer(function () {
            googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                
                let footerFlag; 
                if(event.slot.getSlotElementId() == 'ads-af-pos-1145'){
                    let iframeHtml = event.slot.getHtml();
                    footerFlag = iframeHtml.includes(`"productType": "native-footer"`);
                }

                if (event && event.slot && event.slot.getSlotElementId() == slotid) {
                    debug.log("[webmd][addSlotRenderEndedCallback] called",callback);
                    callback(event, footerFlag);
                } // end if
            }); // end addEventListener()
        }); // end googleDefer()

        this.googleDefer(function() {
            googletag.pubads().addEventListener('slotVisibilityChanged', function (event) {
                var slotId = event.slot.getSlotElementId();
                var inViewPercentage = event.inViewPercentage;
                var checkedAds = checkAdsToWatch(slotId);
                if (checkedAds !== '') profads.ads2.percentTracked[slotId] = inViewPercentage;
            });
        });
        
        this.googleDefer(function () {
            googletag.pubads().addEventListener('impressionViewable', function (event) {
                var slotId = event.slot.getSlotElementId();
                var checkedAds = checkAdsToWatch(slotId);
                var percentTracked = profads.ads2.percentTracked[slotId];
                
                if (checkedAds !== '' && percentTracked > 25) {
                    profads.ads2.impressionViewable(event, slotId);
                }
            });
            googletag.pubads().addEventListener('slotOnload', function(event) {
                window.dispatchEvent(new CustomEvent('onSlotLoad', { detail: { id: slotid } }));

                try {
                    var slotIn = event.slot.getTargeting('pos')[0];
                    if (slotIn === '909' || slotIn === '1909') {
                        var query = '#ads-pos-' + slotIn + ' iframe';
                        var iframeElement = document.querySelector(query);
                        var iframeCheckCount = 0;
                        function checkIframeHeight() {
                            if (iframeElement.getAttribute('data-load-complete') === 'true') {
                                setTimeout(adjustIframeHeight, 1000);
                            } else {
                                if (iframeCheckCount < 5) {
                                    iframeCheckCount++
                                    setTimeout(checkIframeHeight, 1000);
                                }
                            }
                        }
                        function adjustIframeHeight() {
                            if (iframeElement.height === "0") {
                                iframeElement.height = 1;
                            }
                        }
                        checkIframeHeight();
                    }
                } catch (e) {
                    debug.log('[webmd] [slotOnload] event catch block',e);
                }
            })
        });
    },

    /**
     * Disables the initial fetch of ads from Google when the page is first loaded.
     * Calls to refresh() can be used later to display the ads.
     * This must be called before display() is called so it can block the initial load.
     */
    disableRefresh:true,
    disableInitialLoad: function () {
        self.disableRefresh = false;
        this.googleDefer(function () {
            debug.log("[webmd][disableInitialLoad] called");
            googletag.pubads().disableInitialLoad();
        });

        // Allow this function to be chained
        return this;
    },

    /**
     * used to store the state of display function
     */
    displayCalled: false,
    displayCalledCount: 0,
    /**
     * Make the ad call to get all ads, then display the ads.
     * This must be called after all ads have been defined on the page
     * with a call to defineAd() for each one.
     *
     * <p>If you call disableInitialLoad() before calling this function, then the ads will be initialized but will not
     * be loaded and displayed. Then you can call refresh() to later display the ads. This is useful in cases where you
     * need to wait for some other event (for example, to set targeting values) before you load the ads.</p>
     */

    display: function () {
        var self;

        self = this;

        self.googleDefer(function () {

            //display has been called
            self.displayCalled = true;
            //increment the count
            self.displayCalledCount++;

            //trigger DFP_DISPLAY_CALLED only first time the display is called
            if (self.displayCalledCount === 1) {
                debug.log("[webmd][display] called=1 triggering DFP_DISPLAY_CALLED");
                $(document).trigger('DFP_DISPLAY_CALLED');
            }

            //webmd.debug('display');

            // Directs the publisher ads service to make a single request
            // when fetching content for multiple ad slots.
            // This should be called prior to enabling the service.
            googletag.pubads().enableSingleRequest();

            // Enables all Google Publisher Tag services that have been defined for ad slots on the page.
            // This is only needed once per page but including it multiple times will not cause any harm.
            googletag.enableServices();

            // Call display on the ads.
            // Since we are doing enableSingleRequest, the call to display() the first add will make
            // the call to DFP to fetch all the ads. But we must still call display() for each ad on the page.
            function runDisplay() {
                debug.log("[webmd][display] Inside runDisplay",self.ads);
                $.each(self.ads, function (id, adSettings) {

                    googletag.display(id);

                    // Set a flag so we know that "display" has already been called.
                    // This is used when we add another ad on the page: if display has already been called
                    // we must call display() on the ad right when we define the ad.
                    self.display.flag = true;
                });
            }
            
            runDisplay();

        });

        // Allow this function to be chained
        return this;
    },

    /**
     * Refresh some or all ads on the page. The ads must have been created with a call to webmd.ads2.defineAd()
     *
     * @param Object [settings] A set of key/value pairs that configure which ads to refresh.
     *
     * @param Object [settings.keys] Optional set of key/value pairs to set page targeting.
     * Refer to setPageTarget() for more information.
     *
     * @param String|Array [settings.id] The id of an ad to refresh,
     * or an object with several ad ids to refresh.
     * If this parameter is used, then only the ads specified here will be refreshed.
     * If the id is in this list, then even if the ad was defined with refresh:false setting,
     * it will force a refresh.
     *
     * @param String|Array [settings.idSkip] The id of an ad to exclude from refresh,
     * or an object with several ad ids to exclude from refresh.
     * This parameter cannot be used if the settings.id parameter is used.
     *
     * @param String|Array [settings.pos] The POS value of an ad to refresh,
     * or an object with several ad positions to refresh.
     * If this parameter is used, then only the ads specified here will be refreshed.
     * If the pos value is in this list, then even if the ad was defined with refresh:false setting,
     * it will force a refresh.
     *
     * @param String|Array [settings.posSkip] The POS value of an ad to exclude from refresh,
     * or an object with several ad positions to exclude from refresh.
     * This parameter cannot be used if the settings.pos parameter is used.
     *
     * @example
     * // Refresh all ads except those that were defined with refresh:false
     * webmd.ads2.refresh()
     *
     * @example
     * // Refresh all ads except those that were defined with refresh:false,
     * // and change the page targeting before refreshing the ads
     * webmd.ads2.refresh({keys:{xpg:"1012"}})
     *
     * @example
     * // Refresh a single ad position
     * webmd.ads2.refresh({pos:5000});
     *
     * @example
     * // Refresh several ad positions
     * webmd.ads2.refresh({pos:{5000:true,5001:true}});
     *
     * @example
     * // Refresh all ads except for one ad position
     * webmd.ads2.refresh({posSkip:5000});
     *
     * @example
     * // Refresh all ads except for several ad positions
     * webmd.ads2.refresh({posSkip:{5000:true,5001:true}});
     */
    refresh: function (settings, correlatorBoo) {
        debug.log("[webmd][refresh] first line",settings,correlatorBoo);
        var self;

        self = this;

        // Make sure this is an object in case nothing was passed in
        settings = settings || {};

        function invokeRefreshLogic() {
            debug.log("[webmd][refresh][invokeRefreshLogic] first line");
            //webmd.debug('refresh');

            // Create an array of slots to send to refresh function.
            self.slots = [];

            // Set page targeting if new keys were provided
            if (settings.keys) {
                self.setPageTarget(settings.keys);
            }

            // Loop through all the ads that were defined using webmd.ads2.defineAd()
            // so we can gather an array of ads to refresh
            $.each(self.ads, function (id, adSettings) {

                var pos, slot;

                slot = adSettings.slot;
                pos = adSettings.pos;

                // Check if we should refresh only certain ad positions
                // Note: you cannot use both settings.pos and settings.id
                if (typeof settings.pos !== 'undefined') {

                    // Check of the pos values were provided as an object or a single pos
                    if ($.isPlainObject(settings.pos)) {

                        // It is an object, so see if the pos value of this ad is in the list
                        if (settings.pos[pos] === true) {
                            // Show this ad position
                            self.slots.push(slot);
                        }

                    } else {

                        // settings.pos is a single ad position,
                        // so only refresh that one position
                        if (String(pos) === String(settings.pos)) {
                            self.slots.push(slot);
                        }
                    }

                    // Check if we should refresh only certain ad ids
                    // Note: you cannot use both settings.pos and settings.id
                } else if (typeof settings.id !== 'undefined') {

                    // Check of the pos values were provided as an object or a single pos
                    if ($.isPlainObject(settings.id)) {

                        // It is an object, so see if the pos value of this ad is in the list
                        if (settings.id[id] === true) {
                            // Show this ad position
                            self.slots.push(slot);
                        }

                    } else {

                        // settings.id is a single ad id,
                        // so only refresh that one ad
                        if (String(id) === String(settings.id)) {
                            self.slots.push(slot);
                        }
                    }

                } else {

                    // There was not a list of ads to refresh, so we will
                    // refresh each ad unless it was defined as a non-refreshable ad,
                    // and unless it was listed in the posSkip parameter.

                    // If this ad was defined with refresh:false then it should not be refreshed.
                    // Refer to defineAd() for more info.
                    if (adSettings.refresh === false) {
                        // Move on to the next ad in the list
                        return true;
                    }

                    // If this ad pos is in the settings.posSkip list then it should not be refreshed
                    if (typeof settings.posSkip !== 'undefined') {

                        // Check of the posSkip values were provided as an object or a single pos
                        if ($.isPlainObject(settings.posSkip)) {

                            if (settings.posSkip[pos] === true) {
                                // Skip this ad position
                                return true;
                            }

                        } else {

                            // settings.posSkip is a single ad position so only skip that one position
                            if (String(pos) === String(settings.posSkip)) {
                                // Skip this ad position
                                return true;
                            }
                        }
                    }

                    // If this ad id is in the settings.idSkip list then it should not be refreshed
                    if (typeof settings.idSkip !== 'undefined') {

                        // Check of the idSkip values were provided as an object or a single id
                        if ($.isPlainObject(settings.idSkip)) {

                            if (settings.idSkip[id] === true) {
                                // Skip this ad
                                return true;
                            }

                        } else {

                            // settings.idSkip is a single ad id so only skip that one ad
                            if (String(id) === String(settings.idSkip)) {
                                // Skip this ad
                                return true;
                            }
                        }
                    }

                    // If we get here, then the ad should not be skipped,
                    // so add it to the list of ads to be refreshed
                    self.slots.push(slot);
                }
            });

            // PPE-56979: Blank ads on Slideshows, monographs, & quizzes
            // from Google DoubleClick Publisher:
            // Our best bet is to use the GPT clear() function googletag.pubads().clear() in the ad tags before the refresh() function.
            // This will clear the object values of the ad slot where the creative is intended to render before auto-refreshing the slot.
            if (self.slots.length > 0) {
                debug.log("[webmd][refresh][invokeRefreshLogic] clear slots",self.slots);
                googletag.pubads().clear(self.slots);
            }
            else {
                googletag.pubads().clear();
            }
            // remove blank ad class name on refresh
            profads.ads2.clearBlankAds();
            //add medtab  & uri key
			profads.ads2.addDynamicTargetKeys();

            if (correlatorBoo == null) {
                debug.log("[webmd][refresh][invokeRefreshLogic] refresh slots empty correlatorBoo",self.slots);
                googletag.pubads().refresh(self.slots);
            } else {
                debug.log("[webmd][refresh][invokeRefreshLogic] refresh slots non-empty correlatorBoo",self.slots);
                googletag.pubads().refresh(self.slots, {changeCorrelator: correlatorBoo});
            }
        }

        //if display was called before this,
        //fire invokeRefreshLogic
        if (self.displayCalled) {
            debug.log("[webmd][refresh] displayCalled=true ");
            if (typeof webmd.tpsvc !== 'undefined') {
                debug.log("[webmd][refresh] webmd.tpsvc thirdparties avilable",webmd.tpsvc);
                self.callThirdPartyAdServices(invokeRefreshLogic);
            } else {
                debug.log("[webmd][refresh] no third parties");
                invokeRefreshLogic();
            }
        }

        //otherwise, wait for DFP_DISPLAY_CALLED and then
        //fire invokeRefreshLogic
        else {
            debug.log("[webmd][refresh] displayCalled = false ");
            $(document).on('DFP_DISPLAY_CALLED', function () {
                //webmd.debug('invoking refresh using event');
                const fn = function () {
                    //using setTimeout to skip curerent frame before invoking
                    debug.log("[webmd][refresh] Before calling invokeRefreshLogic");
                    setTimeout(invokeRefreshLogic, 1);
                };

                if (typeof webmd.tpsvc !== 'undefined') {
                    debug.log("[webmd][refresh] Thirdparties available call them first",webmd.tpsvc);
                    self.callThirdPartyAdServices(fn);
                } else {
                    debug.log("[webmd][refresh] no third parties");
                    fn();
                }
            });
        }

        // Allow this function to be chained
        return this;

    },

    /*
     *   !  from consumer implementation: https://stash.portal.webmd.com/projects/CONSUI/repos/scripts/browse/src/common/webmd.ads2.js#653,753,782,794,823
     * Proxy method to all 3rd party ad services.
     * All 3rd party Ad service handlers are stored in the thirdPartyAdServices global variable.
     * This method executes each of those handlers and delays the ad request for a specified length of time
     * so that 3rd party ad services get a chance to complete processing.
     * if all services finish processing before the timeout elapses, the ad request is sent.
     * otherwise, the ad request will be sent once the timeout expires.
     *
     * This all happens asynchronously to allow us to work with multiple 3rd party services
     * while keeping the timeout delay near constant.
     *
     * @param Object adRequest - Ad request handler.
     * @return {void}
     */
    callThirdPartyAdServices: function (adRequest) {
        let cmdsLength = webmd.tpsvc.cmds.length;
        let promisesLength = webmd.tpsvc.promises.length;
        let promises = webmd.tpsvc.promises;
            promises = promises.map(e => e())
         // if there are no 3rd party ad services to be executed, make the ad request and exit
         if (cmdsLength < 1 && promisesLength < 1) {
            debug.log("[webmd][callThirdPartyAdServices] cmds and promises length < 1");
             adRequest();
             return;
         }
         //new promise logic to wait ad service
 
         // Set a timeout for 500ms
         const timeout = setTimeout(() => {
             debug.log("[webmd][callThirdPartyAdServices] Promises not resolved within 500ms."); 
             // Execute regular flow of code here
             adRequest();
             
         }, 600);
         
         // Wait for both Promises to settle and clear the timeout
         Promise.allSettled(promises)
             .then(results => {
                // call all 3rd ad party services that have been defined and queued in the services array
                for (var i = 0; i < window.webmd.tpsvc.cmds.length; i++) {
                    try {
                        window.webmd.tpsvc.cmds[i].call();
                    } catch (e) {
                        debug.warn("[webmd][callThirdPartyAdServices] promise was rejected with reason",e);
                    }
                }
                clearTimeout(timeout);
                adRequest();
                // Handle the results of the settled Promises here
                debug.log('[webmd][callThirdPartyAdServices] Promises settled:', results);
         });
    },

    // safety check for fluid sizes that may be overwritten outside of profads
    addFluidSize: function(settings) {
        let fluidPos = [
            1122,
            1522
        ];
        if(fluidPos.indexOf(settings.pos) !== -1 && settings.sizes.indexOf('fluid') == -1) settings.sizes.push('fluid');
        return settings.sizes;
    },
    
    // Set publisher-provided ID for Google Ad Manager
    setPublisherProvidedId(ppid){
        const self = this;
        self.googleDefer(function() {
            googletag.pubads().setPublisherProvidedId(ppid);
        });
    },
    //Sandbox code
    sandboxInit(){
        const urlParams = new URLSearchParams(window.location.search);
        this.sandboxParams = {};
        ['secd', 'pos'].forEach((param) => {
        const value = urlParams.get(param);
            if (value !== null) {
                this.sandboxParams[param] = value.split(',');
            // debug.log(`Sandbox ${param}:`, sandboxParams[param]);
            }
        });
        this.sandboxSecd = this.sandboxParams['secd'];
        this.sandboxPos = this.sandboxParams['pos'];
    }
};
//export { webmd as default };
export default webmd;